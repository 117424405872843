import { formatYYYYMMDD } from '../../../util/date-time/dateTimeFormat';
import React, { FC } from 'react';
import { TaskFieldProps } from '../../../interfaces';
import { determineSortDate } from '../../../util/task/determineSortDate';

export const DueDateField: FC<TaskFieldProps> = ({ enabledField, task }) => {
  let formattedDueDate = '';

  const dueDate = determineSortDate(task);
  if (dueDate !== Number.MAX_VALUE)
    formattedDueDate = formatYYYYMMDD(new Date(dueDate));

  return (
    <div key={enabledField.id} className="field is-disabled DueDate">
      {formattedDueDate}
    </div>
  );
};

export default DueDateField;

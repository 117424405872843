import { Locale, Theme } from '../enums';
import { isValidObject } from './validationFunctions';

export const getThemeName = (): Theme => {
  return (localStorage.getItem('theme') as Theme) || Theme.DARK;
};

export const getLocale = (): Locale => {
  return localStorage.getItem('locale')
    ? (localStorage.getItem('locale') as Locale)
    : Locale.ENGLISH_US;
};

export const checkFlagValue = (value: string): boolean => {
  const showCompletedItems = localStorage.getItem(value);
  if (!isValidObject(showCompletedItems)) return false;
  return showCompletedItems === 'true';
};

export const getIntegration = (): any => {
  const data = sessionStorage.getItem('integration');
  if (data) {
    return JSON.parse(data);
  } else {
    return {
      enabled: false,
      data: {},
    };
  }
};

import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { GlobalState, Task } from '../../../interfaces';
import { useSelector } from 'react-redux';
import { StatusBoard } from './StatusBoard';
import './ToDoBoards.scss';
import { WorkflowBoard } from './WorkflowBoard';
import { sortWorkflowsByNameAndProject } from '../../../util/workflow/sortWorkflowsByNameAndProject';
import FilterBar from '../../ToDoListPage/ToDoList/FilterBar/FilterBar';
import { getFilteredToDos } from '../../../util/task/getFilteredToDos';
import { appendDueDateInToDos } from '../../../util/task/appendDueDateInToDos';
import { isValidObject } from '../../../util/validationFunctions';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import { Workflow } from '../../../interfaces/workflow';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';

interface IProps {
  showCoverImages: boolean;
}

export const ToDoBoards: FC<IProps> = memo(({ showCoverImages }) => {
  document.title = 'Hansoft';

  const todos: Task[] = useSelector(
    (state: GlobalState) => getFilteredToDos(appendDueDateInToDos(state.todos)),
    isEqual,
  );
  const workflows = useSelector((state: GlobalState) => state.workflows);
  const errorOccurred = useSelector((state: GlobalState) => state.error);
  const defaultStatusField = useSelector((state: GlobalState) =>
    state.singleLineFields.find((field) => field.id === 'Status'),
  );

  const showFilterBar = useSelector(
    (state: GlobalState) => state.isFilterBarCollapsed,
  );

  const intl = useIntl();

  const [currentTodos, setCurrentTodos] = useState(todos);

  useEffect(() => {
    setCurrentTodos([...todos]);
  }, [errorOccurred.isError, todos]);

  const todosWithNoWorkflow = currentTodos.filter(
    (todo) =>
      todo.fields.Workflow === -1 || !isValidObject(todo.fields.Workflow),
  );

  const todosWithWorkflow = currentTodos.filter(
    (todo) => todo.fields.Workflow !== -1,
  );

  const groupTodosByWorkflow = (workflow: Workflow): Task[] =>
    todosWithWorkflow.filter(
      (todo) =>
        todo.fields.Workflow == workflow.id &&
        getProjectInfo(todo.$ProjectID)[1]?.id == workflow.projectID,
    );

  const updateTodos = useCallback(() => {
    setCurrentTodos([...todos]);
  }, [todos, setCurrentTodos]);

  return (
    <div className="container">
      {!showFilterBar && <FilterBar />}
      {todos.length > 0 ? (
        <div className="toDoBoards">
          <StatusBoard
            showCoverImages={showCoverImages}
            todos={todosWithNoWorkflow}
            statusField={defaultStatusField}
            updateColumns={updateTodos}
          />
          {sortWorkflowsByNameAndProject(workflows).map(
            (workflow: Workflow, index: number) => (
              <WorkflowBoard
                key={index}
                workflowStatus={workflow}
                showCoverImages={showCoverImages}
                todos={groupTodosByWorkflow(workflow)}
                updateColumns={updateTodos}
                projectId={workflow.projectID}
              />
            ),
          )}
        </div>
      ) : (
        <div className="empty-boards-message">
          🙌{' '}
          {intl.formatMessage({
            id: 'GENERAL.emptyTodoItemsMessage',
            defaultMessage: 'There are no items assigned to you',
          })}
        </div>
      )}
    </div>
  );
});

export default ToDoBoards;

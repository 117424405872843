import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';

interface IProps {
  onThemeToggle(): void;
}

export const ThemeToggle: FC<IProps> = ({ onThemeToggle }) => {
  return (
    <div className="profile-menu" onClick={onThemeToggle}>
      <Icon name="adjust" />
    </div>
  );
};

export default ThemeToggle;

import { FC, memo, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dropdown, Icon } from 'semantic-ui-react';

import {
  logoffHansoft,
  clearAuthToken,
  hasDashboardsAccess,
} from '../../DDPJS/DDPJS';
import { deleteRefreshToken } from '../../tokens/refresh.token';
import { ImgLogoCollapsed, ImgFunnel } from '../../images/images';

import './TitleBar.scss';
import { stringConstants } from '../../constants/strings.constants';
import { Routes } from '../../enums';
import { FormattedMessage, useIntl } from 'react-intl';
import { LanguageDropdown } from './LanguageDropdown';
import ThemeToggle from './ThemeToggle/ThemeToggle';
import { GlobalState, SharedProps } from '../../interfaces';
import { checkFlagValue, getThemeName } from '../../util/localStorage';
import { isValidObject } from '../../util/validationFunctions';
import GeneralStyle from '../../GeneralStyle';
import CreateTaskModal from '../CreateTaskModal';

interface IProps {
  shared: SharedProps;
  currentRoute?: string;
  onToggleFilter?(): void;
}

export const TitleBar: FC<IProps> = memo(
  ({ shared, currentRoute, onToggleFilter }) => {
    const [createTaskIsOpen, setCreateTaskIsOpen] = useState<boolean>(false);

    const history = useHistory();
    const intl = useIntl();

    const appState = useSelector((state: GlobalState) => state.appState);
    const username = useSelector(
      (state: GlobalState) => state.currentUser.username,
    );
    const resources = useSelector((state: GlobalState) => state.resources);
    const isFilterBarCollapsed = useSelector(
      (state: GlobalState) => state.isFilterBarCollapsed,
    );

    useEffect(() => {
      const isTaskOpen: boolean =
        history.location.search.indexOf('create_task') > -1;
      setCreateTaskIsOpen(isTaskOpen);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onLogout = (): void => {
      logoffHansoft();
      clearAuthToken();
      deleteRefreshToken();
      history.push(Routes.LOGIN);
    };

    const onHelpClick = (): void => {
      window.open(stringConstants.PERFORCE_HANSOFT_HELP_LINK);
    };

    const navigateToRoute = (route: Routes): void => {
      history.push(route);
    };

    const navigateToRoot = (): void => {
      if (shared.integration.enabled) {
        navigateToRoute(shared.integration.data.redirectPath);
      } else {
        navigateToRoute(Routes.TODO_LIST);
      }
    };

    const closeTaskModal = (): void => {
      setCreateTaskIsOpen(false);
    };

    const openTaskModal = (): void => {
      setCreateTaskIsOpen(true);
    };

    const onTaskCreation = (createdTaskId: string): void => {
      setCreateTaskIsOpen(false);

      if (createdTaskId)
        history.push(`/task/${appState.databaseGUIDHash}/${createdTaskId}`);
    };

    const getUsername = useMemo(() => {
      const userResource = resources.find(
        (resource) => resource.Name.toUpperCase() === username?.toUpperCase(),
      );

      return isValidObject(userResource) ? userResource?.Name : username;
    }, [resources, username]);

    const filterIsOpen = !isFilterBarCollapsed;

    return (
      <div className="titlebar">
        <div className="title">
          <img className="logo" src={ImgLogoCollapsed} alt="" />

          <div className="navigation-list">
            <div
              className={`titlebar-button ${
                currentRoute === Routes.TODO_LIST && 'is-active'
              } js-todolist`}
              onClick={navigateToRoot}
            >
              <FormattedMessage id="TODO_LIST.todoList" />
            </div>
            <div
              className={`titlebar-button ${
                currentRoute === Routes.TODO_BOARDS && 'is-active'
              }`}
              onClick={() => navigateToRoute(Routes.TODO_BOARDS)}
            >
              <FormattedMessage id="TODO_LIST.todoBoard" />
            </div>
            {hasDashboardsAccess() && (
              <div
                className={`titlebar-button ${
                  currentRoute === Routes.DASHBOARDS && 'is-active'
                } js-dashboards`}
                onClick={() => navigateToRoute(Routes.DASHBOARDS)}
              >
                <FormattedMessage id="DASHBOARDS.dashboards" />
              </div>
            )}
            <div
              className="titlebar-button is-primary js-create-bug"
              onClick={openTaskModal}
            >
              <FormattedMessage id="CREATE_BUG.newBug" />
            </div>
          </div>
        </div>
        <div className="controls">
          {onToggleFilter && (
            <>
              <div className="titlebar-button" onClick={onToggleFilter}>
                <img src={ImgFunnel} alt="" />
                {filterIsOpen ? (
                  <FormattedMessage id="GENERAL.closeFilter" />
                ) : (
                  <FormattedMessage id="GENERAL.filter" />
                )}
              </div>
            </>
          )}
          <Dropdown className="profile-menu js-account-menu" text={getUsername}>
            <Dropdown.Menu>
              <Dropdown.Item
                className="item-with-checkbox"
                onClick={shared.onToggleCoverImages}
                text={intl.formatMessage({ id: 'GENERAL.showCoverImages' })}
                icon={
                  <Icon name="checkmark" inverted={!shared.showCoverImages} />
                }
              />
              <Dropdown.Item
                className="js-menu-coverimages item-with-checkbox"
                onClick={shared.onToggleShowCompletedItems}
                text={intl.formatMessage({ id: 'GENERAL.showCompletedItems' })}
                icon={
                  <Icon
                    name="checkmark"
                    inverted={!checkFlagValue('showCompletedItems')}
                  />
                }
              />
              <Dropdown.Item
                className="item-with-checkbox"
                onClick={shared.onToggleShowOnlyFourWeeksOfTasks}
                text={intl.formatMessage({
                  id: 'GENERAL.showOnlyFourWeeksOfTasks',
                })}
                icon={
                  <Icon
                    name="checkmark"
                    inverted={!checkFlagValue('showOnlyFourWeeksOfTasks')}
                  />
                }
              />
              <Dropdown.Item
                onClick={onHelpClick}
                text={`${intl.formatMessage({ id: 'GENERAL.help' })}...`}
              />
              <Dropdown.Divider />
              <Dropdown.Item
                className="js-menu-logout"
                onClick={onLogout}
                text={intl.formatMessage({ id: 'LOGIN.logout' })}
              />
            </Dropdown.Menu>
          </Dropdown>
          <div className="vertical-line" />
          <LanguageDropdown
            locale={shared.locale}
            onToggleLocale={shared.onToggleLocale}
          />
          <ThemeToggle onThemeToggle={shared.onToggleTheme} />
        </div>
        <Modal
          isOpen={createTaskIsOpen}
          className={`theme--${getThemeName()} task-create-modal`}
          style={GeneralStyle.ReactModal}
          onRequestClose={closeTaskModal}
        >
          <CreateTaskModal
            onCancel={closeTaskModal}
            onCreated={onTaskCreation}
          />
        </Modal>
      </div>
    );
  },
);

export default TitleBar;

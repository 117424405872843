import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';
import { AppState } from '../../interfaces';

const clearCache = createAction(CLEAR_CACHE);

const initialState: AppState = {
  databaseGUIDHash: '',
  databaseServerURL: '',
  isConnected: false,
  currentTaskID: '',
  currentDashboardID: '',
  editMode: false,
  loadingMode: false,
  hasDashboardsAccess: false,
  featureToggles: [],
  serverVersion: '',
  serviceVersion: '',
};

const slice = createSlice({
  name: 'AppState',
  initialState,
  reducers: {
    setConnected: (state: AppState): AppState => {
      return { ...state, isConnected: true };
    },
    clearConnected: (state: AppState): AppState => {
      return { ...state, isConnected: false };
    },
    setLoadingMode: (state: AppState): AppState => {
      return { ...state, loadingMode: true };
    },
    clearLoadingMode: (state: AppState): AppState => {
      return { ...state, loadingMode: false };
    },
    setDatabaseGUIDHash: (state: AppState, { payload }): AppState => {
      return {
        ...state,
        databaseGUIDHash: payload.databaseGUIDHash,
        databaseServerURL: payload.databaseServerURL,
      };
    },
    setCurrentTaskID: (state: AppState, { payload }): AppState => {
      return { ...state, currentTaskID: payload.currentTaskID };
    },
    setFeatureToggles: (state: AppState, { payload }): AppState => {
      return { ...state, featureToggles: payload.featureToggles };
    },
    setCurrentDashboardID: (state: AppState, { payload }): AppState => {
      return { ...state, currentDashboardID: payload.currentDashboardID };
    },
    clearCurrentTaskID: (state: AppState): AppState => {
      return { ...state, currentTaskID: '' };
    },
    setServerVersion: (state: AppState, { payload }): AppState => {
      return { ...state, serverVersion: payload.serverVersion };
    },
    clearServerVersion: (state: AppState): AppState => {
      return { ...state, serverVersion: '' };
    },
    setServiceVersion: (state: AppState, { payload }): AppState => {
      return { ...state, serviceVersion: payload.serviceVersion };
    },
    clearServiceVersion: (state: AppState): AppState => {
      return { ...state, serviceVersion: '' };
    },
    setHasDashboardsAccess: (state: AppState, { payload }): AppState => {
      return { ...state, hasDashboardsAccess: payload.hasDashboardsAccess };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(clearCache), (state): AppState => {
      return {
        ...state,
        currentTaskID: '',
        currentDashboardID: '',
        editMode: false,
        loadingMode: false,
        databaseGUIDHash: '',
        databaseServerURL: '',
        featureToggles: [],
      };
    });
  },
});

export const {
  setConnected,
  setCurrentTaskID,
  setDatabaseGUIDHash,
  setFeatureToggles,
  setHasDashboardsAccess,
  setLoadingMode,
  setServerVersion,
  setServiceVersion,
  setCurrentDashboardID,
  clearCurrentTaskID,
  clearServerVersion,
  clearServiceVersion,
  clearLoadingMode,
  clearConnected,
} = slice.actions;
export const appState = slice.reducer;

import { FC, memo, useCallback, useMemo } from 'react';
import { Select } from '../../Fields/Select';
import {
  ImgBlocked,
  ImgCompleted,
  ImgInProgress,
  ImgNotDone,
  ImgToBeDeleted,
} from '../../../images/images';
import { useIntl } from 'react-intl';
import { checkFlagValue } from '../../../util/localStorage';

interface IProps {
  isActive: boolean;
  statusFilters: number[];
  onSelectedFiltersChange(selectedFilters: number[]): void;
}

export const Status: FC<IProps> = memo(
  ({ isActive, statusFilters, onSelectedFiltersChange }) => {
    const intl = useIntl();

    const getTextForStatus = useCallback(
      (id: number): string => {
        switch (id) {
          case 6:
            return intl.formatMessage({
              id: 'STATUS.toBeDeleted',
            });
          case 5:
            return intl.formatMessage({
              id: 'STATUS.blocked',
            });
          case 4:
            return intl.formatMessage({
              id: 'STATUS.completed',
            });
          case 3:
            return intl.formatMessage({
              id: 'STATUS.inProgress',
            });
          case 2:
            return intl.formatMessage({
              id: 'STATUS.notDone',
            });
          default:
            return '<unknown>';
        }
      },
      [intl],
    );

    const getOptions = useMemo(() => {
      const options = [
        {
          value: 5,
          image: ImgBlocked,
          text: intl.formatMessage({ id: 'STATUS.blocked' }),
          selected: statusFilters.indexOf(5) !== -1,
        },
        {
          value: 3,
          image: ImgInProgress,
          text: intl.formatMessage({ id: 'STATUS.inProgress' }),
          selected: statusFilters.indexOf(3) !== -1,
        },
        {
          value: 2,
          image: ImgNotDone,
          text: intl.formatMessage({ id: 'STATUS.notDone' }),
          selected: statusFilters.indexOf(2) !== -1,
        },
        {
          value: 6,
          image: ImgToBeDeleted,
          text: intl.formatMessage({ id: 'STATUS.toBeDeleted' }),
          selected: statusFilters.indexOf(6) !== -1,
        },
      ];

      if (checkFlagValue('showCompletedItems')) {
        options.push({
          value: 4,
          image: ImgCompleted,
          text: intl.formatMessage({ id: 'STATUS.completed' }),
          selected: statusFilters.indexOf(4) !== -1,
        });
      }

      return options;
    }, [checkFlagValue('showCompletedItems'), statusFilters]);

    const displayStatusFilter = useMemo(() => {
      let filterString = `${intl.formatMessage({
        id: 'TODO_LIST_FIELDS.Status',
      })}: `;
      if (statusFilters.length === 1) {
        return filterString + getTextForStatus(statusFilters[0]);
      } else if (statusFilters.length > 1) {
        filterString += getTextForStatus([...statusFilters].sort()[0]);
        filterString += ',...';
        return filterString;
      } else {
        return '';
      }
    }, [getTextForStatus, intl, statusFilters]);

    return (
      <Select
        text={
          isActive
            ? displayStatusFilter
            : intl.formatMessage({ id: 'TODO_LIST_FIELDS.Status' })
        }
        multiSelection={true}
        onSelectionChanged={onSelectedFiltersChange}
        options={getOptions}
      />
    );
  },
);

export default Status;

import {
  ChangeEvent,
  FC,
  memo,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react';
import { setField } from '../../../DDPJS/DDPJS';
import { ItemFieldWrapper } from '../../ItemDetailsPage/ItemFieldWrapper';
import { isValidObject } from '../../../util/validationFunctions';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import { Task } from '../../../interfaces';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../helpers';

interface IProps {
  task: Task;
  fieldID: string;
}

export const String: FC<IProps> = memo(({ task, fieldID }) => {
  const [value, setValue] = useState(getTaskProperty(task, fieldID));
  const [originalValue, setOriginalValue] = useState(
    getTaskProperty(task, fieldID),
  );

  useEffect(() => {
    if (originalValue !== getTaskProperty(task, fieldID)) {
      setValue(getTaskProperty(task, fieldID));
      setOriginalValue(getTaskProperty(task, fieldID));
    }
  }, [getTaskProperty(task, fieldID)]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!checkIfFieldIsReadOnly(fieldID, task)) {
      setValue(event.currentTarget.value);
    }
  };

  const onBlur = () => {
    if (!checkIfFieldIsReadOnly(fieldID, task) && value !== originalValue) {
      setOriginalValue(value);
    }

    setField(getFieldDefinition(fieldID, task).id, task.$ID, value);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onBlur();
    }
  };

  if (!isValidObject(getFieldDefinition(fieldID, task))) {
    return null;
  }

  return (
    <ItemFieldWrapper
      fieldName={getFieldDefinition(fieldID, task).DisplayName}
      isRequiredField={isWorkflowRequiredField(fieldID, task)}
    >
      <input
        readOnly={checkIfFieldIsReadOnly(fieldID, task)}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={handleKeyPress}
        value={value}
      />
    </ItemFieldWrapper>
  );
});

export default String;

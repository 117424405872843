import { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ItemDetails } from './ItemDetails';
import MessageDialog from '../MessageDialog/MessageDialog';
import { triggerMessageDialog } from '../MessageDialog/MessageUtilities';
import TitleBar from '../TitleBar/TitleBar';
import { GlobalState, SharedProps } from '../../interfaces';
import { useParams } from 'react-router-dom';
interface MatchParams {
  id: string;
  databaseGUIDHash: string;
}

interface IProps {
  shared: SharedProps;
}

export const ItemDetailsPage: FC<IProps> = memo(({ shared }) => {
  const params = useParams<MatchParams>();
  const error = useSelector((state: GlobalState) => state.error);
  const appState = useSelector((state: GlobalState) => state.appState);

  useEffect(() => {
    if (error.isError) {
      triggerMessageDialog(error.isError);
    }
  }, [error]);

  useEffect(() => {
    if (error.isError) {
      triggerMessageDialog(error.isError);
    }
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <MessageDialog />
      <TitleBar shared={shared} />
      {appState.databaseGUIDHash && (
        <ItemDetails
          databaseGUIDHash={params.databaseGUIDHash}
          id={params.id}
          shared={shared}
        />
      )}
    </div>
  );
});

export default ItemDetailsPage;

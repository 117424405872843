import {
  createAction,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { UserState } from './types';
import { CLEAR_CACHE } from '../../constants/general.constants';

const initialState: UserState = {};

const clearCache = createAction(CLEAR_CACHE);

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    storeUser: (state: UserState, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { storeUser } = slice.actions;
export const currentUser = slice.reducer;

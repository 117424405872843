import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';

const initialState = true;

const clearCache = createAction(CLEAR_CACHE);

const slice = createSlice({
  name: 'filterBar',
  initialState,
  reducers: {
    toggleFilterBar: (state): boolean => {
      return !state;
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const { toggleFilterBar } = slice.actions;
export const isFilterBarCollapsed = slice.reducer;

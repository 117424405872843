import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import './filterbar.scss';
import SearchField from '../../../TitleBar/SearchField';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Status } from '../../../Filters/Status';
import { DueDate } from '../../../Filters/DueDate';
import { Priority } from '../../../Filters/Priority';
import { Project } from '../../../Filters/Project';
import {
  changeDueDateFilter,
  changePriorityFilter,
  changeProjectFilter,
  changeStatusFilter,
} from '../../../../store/filterOptions/slice';
import {
  ToDoListFilter,
  Project as ProjectType,
  GlobalState,
} from '../../../../interfaces';

interface IProps extends WrappedComponentProps {
  filterOptions: ToDoListFilter;
  projects: ProjectType[];
  dispatch(message: any): void;
}

export class FilterBar extends Component<IProps> {
  render(): ReactNode {
    const { filterOptions, projects, dispatch } = this.props;

    const dueFilterActive = filterOptions.dueDateFilters.length >= 1;
    const priorityFilterActive = filterOptions.priorityFilters.length >= 1;
    const statusFilterActive = filterOptions.statusFilters.length >= 1;
    const projectFilterActive = filterOptions.projectFilters.length >= 1;

    return (
      <div className="filterbar">
        <SearchField />
        <div
          id="dueDateFilter"
          className={
            dueFilterActive ? 'filterbutton is-active' : 'filterbutton'
          }
        >
          <DueDate
            isActive={dueFilterActive}
            dueDateFilters={filterOptions.dueDateFilters}
            onSelectedFiltersChange={(newValues) => {
              const message = {
                dueDateFilters: newValues,
              };
              dispatch(changeDueDateFilter(message));
            }}
          />
        </div>
        <div
          className={
            projectFilterActive ? 'filterbutton is-active' : 'filterbutton'
          }
          id="projectFilter"
        >
          <Project
            isActive={projectFilterActive}
            projectFilters={filterOptions.projectFilters}
            projects={projects}
            onSelectedFiltersChange={(newValues) => {
              const message = {
                projectFilters: newValues,
              };
              dispatch(changeProjectFilter(message));
            }}
          />
        </div>
        <div
          className={
            statusFilterActive ? 'filterbutton is-active' : 'filterbutton'
          }
          id="statusFilter"
        >
          <Status
            isActive={statusFilterActive}
            statusFilters={filterOptions.statusFilters}
            onSelectedFiltersChange={(newValues) => {
              const message = {
                statusFilters: newValues,
              };
              dispatch(changeStatusFilter(message));
            }}
          />
        </div>
        <div
          className={
            priorityFilterActive ? 'filterbutton is-active' : 'filterbutton'
          }
          id="priorityFilter"
        >
          <Priority
            isActive={priorityFilterActive}
            priorityFilters={filterOptions.priorityFilters}
            onSelectedFiltersChange={(newValues) => {
              const message = {
                priorityFilters: newValues,
              };

              dispatch(changePriorityFilter(message));
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  filterOptions: state.filterOptions,
  projects: state.projects,
});

export default injectIntl(connect(mapStateToProps)(FilterBar));

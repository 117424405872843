import { FC, memo, useEffect, useState } from 'react';
import {
  setField,
  subscribeWithParams,
  unsubscribeFromSubscriptionId,
} from '../../../DDPJS/DDPJS';
import { ImgUserGreen, ImgUserGroup } from '../../../images/images';
import { Select } from '../Select';
import { isValidObject } from '../../../util/validationFunctions';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import { getTaskProperty, taskIsQA } from '../../../util/task/propertyHelpers';
import { ResourceUserType } from '../../../enums/resourceUserTypes';
import { ResourceEntryType } from '../../../enums';
import { Resource, ResourceGroup, Task } from '../../../interfaces';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../helpers';

interface IProps {
  task: Task;
  fieldID: string;
  resources: Resource[];
  groups: ResourceGroup[];
}

export const Resources: FC<IProps> = memo(
  ({ task, fieldID, resources, groups }) => {
    const subscriptionParameters = {
      projectID: task.$ProjectID,
      sprintID: -1,
      bIncludeGroups: true,
      findText: '',
    };

    const [value, setValue] = useState(
      buildResourceValueArray(getTaskProperty(task, fieldID)),
    );
    const [subscriptionID, setSubscriptionID] = useState(
      subscribeWithParams('ProjectResources', subscriptionParameters),
    );

    useEffect(() => {
      if (value !== buildResourceValueArray(getTaskProperty(task, fieldID))) {
        setValue(buildResourceValueArray(getTaskProperty(task, fieldID)));
      }
    }, [getTaskProperty(task, fieldID)]);

    useEffect(() => {
      return () => {
        if (subscriptionID) {
          unsubscribeFromSubscriptionId(subscriptionID);
        }
      };
    }, []);

    const onChange = (value: any) => {
      if (checkIfFieldIsReadOnly(fieldID, task)) return;

      setValue(value);
      const updateArray = parseSelectionArray(value);
      setField(getFieldDefinition(fieldID, task).id, task.$ID, updateArray);
    };

    const onFindDataChanged = (newFindText: string) => {
      if (subscriptionID !== '') {
        unsubscribeFromSubscriptionId(subscriptionID);
      }

      subscriptionParameters.findText = newFindText;

      setSubscriptionID(
        subscribeWithParams('ProjectResources', subscriptionParameters),
      );
    };

    if (!isValidObject(getFieldDefinition(fieldID, task))) {
      return null;
    }

    const projectData = getProjectInfo(task.$ProjectID);
    if (!projectData || !projectData[1]) return null;

    const resourcesLength = resources ? resources.length : 0;
    const groupsLength = groups ? groups.length : 0;

    const options = [];

    const currentValues = buildResourceValueArray(
      getTaskProperty(task, fieldID),
    );
    let displayValue = '';
    const userDisplayValues: any = [];
    const groupsDisplayValues: any = [];

    const selectedUsersLookup = {};

    for (let index = 0; index < resourcesLength; ++index) {
      if (
        resources[index].projectID !== projectData[1].id ||
        (resources[index].Type !== ResourceUserType.EPM_ResourceType_Normal &&
          !taskIsQA(task)) ||
        (resources[index].Type !== ResourceUserType.EPM_ResourceType_Normal &&
          resources[index].Type !==
            ResourceUserType.EPM_ResourceType_QA_Account)
      )
        continue;

      const value = buildResourceValue(
        ResourceEntryType.NORMAL,
        resources[index].id,
      );
      const isSelected = currentValues.indexOf(value) !== -1;

      options.push({
        image: ImgUserGreen,
        selected: isSelected,
        text: resources[index].Name,
        sortValue: resources[index].SortName,
        value: value,
      });

      if (isSelected) selectedUsersLookup[value] = resources[index].Name;
    }

    const selectedGroupsLookup = {};

    for (let index = 0; index < groupsLength; ++index) {
      const value = buildResourceValue(
        ResourceEntryType.GROUP,
        groups[index].id,
      );
      const isSelected = currentValues.indexOf(value) !== -1;

      options.push({
        image: ImgUserGroup,
        selected: isSelected,
        text: groups[index].Name,
        sortValue: groups[index].SortName,
        value: value,
      });

      if (isSelected) selectedGroupsLookup[value] = groups[index].Name;
    }

    currentValues.forEach((value) => {
      if (isValidObject(selectedUsersLookup[value])) {
        userDisplayValues.push(selectedUsersLookup[value]);
      } else if (isValidObject(selectedGroupsLookup[value])) {
        groupsDisplayValues.push(selectedGroupsLookup[value]);
      }
    });

    displayValue = userDisplayValues.concat(groupsDisplayValues).join('; ');

    options.sort((first, second) => {
      const firstSortName = first.sortValue ? first.sortValue : first.text;
      const secondSortName = second.sortValue ? second.sortValue : second.text;

      if (firstSortName.toUpperCase() < secondSortName.toUpperCase()) return -1;
      if (firstSortName.toUpperCase() > secondSortName.toUpperCase()) return 1;
      return 0;
    });

    return (
      <Select
        subscriptionId={subscriptionID}
        text={displayValue}
        fieldName={getFieldDefinition(fieldID, task).DisplayName}
        disabled={checkIfFieldIsReadOnly(fieldID, task)}
        isRequiredField={isWorkflowRequiredField(fieldID, task)}
        multiSelection={true}
        onSelectionChanged={onChange}
        findData={{
          onFindTextChanged: (newFindText) => {
            onFindDataChanged(newFindText);
          },
        }}
        options={options}
      />
    );
  },
);

function buildResourceValueArray(array: any[]) {
  const returnArray = [];
  if (array) {
    const arrayLen = array.length;
    for (let index = 0; index < arrayLen; ++index) {
      const myString = buildResourceValue(array[index][0], array[index][1]);
      returnArray.push(myString);
    }
    return returnArray;
  } else {
    return [];
  }
}

function buildResourceValue(type: any, value: any) {
  return type.toString() + ':' + value.toString();
}

function parseSelectionArray(array: any[]) {
  const returnArray = [];
  const arrayLen = array.length;
  for (let index = 0; index < arrayLen; ++index) {
    const myArray = parseSelection(array[index]);
    returnArray.push(myArray);
  }
  return returnArray;
}

function parseSelection(selection: string) {
  const parsedArray: any[] = selection.split(':');
  const arrayLen = parsedArray.length;
  for (let index = 0; index < arrayLen; ++index) {
    parsedArray[index] = parseInt(parsedArray[index], 10);
  }
  return parsedArray;
}

export default Resources;

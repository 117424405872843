import { FC, memo, useCallback, useMemo } from 'react';
import { Select } from '../../Fields/Select';
import { useIntl } from 'react-intl';
import {
  ImgPriorityHigh,
  ImgPriorityLow,
  ImgPriorityMedium,
  ImgPriorityNone,
  ImgPriorityVeryHigh,
  ImgPriorityVeryLow,
} from '../../../images/images';

interface IProps {
  isActive: boolean;
  priorityFilters: number[];
  onSelectedFiltersChange(selectedFilters: number[]): void;
}

export const Priority: FC<IProps> = memo(
  ({ isActive, priorityFilters, onSelectedFiltersChange }) => {
    const intl = useIntl();

    const getTextForPriority = useCallback(
      (id: number): string => {
        switch (id) {
          case 6:
            return intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.veryHighPriority',
            });
          case 5:
            return intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.highPriority',
            });
          case 4:
            return intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.mediumPriority',
            });
          case 3:
            return intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.lowPriority',
            });
          case 2:
            return intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.veryLowPriority',
            });
          case 1:
            return `${intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.noPrioritySet',
            })}`;
          default:
            return '<unknown>';
        }
      },
      [intl],
    );

    const displayPriorityFilter = useMemo(() => {
      let filterString = `${intl.formatMessage({
        id: 'TODO_LIST.priority',
      })}: `;
      if (priorityFilters.length === 1) {
        return filterString + getTextForPriority(priorityFilters[0]);
      } else if (priorityFilters.length > 1) {
        filterString += getTextForPriority([...priorityFilters].sort()[0]);
        filterString += ',...';
        return filterString;
      } else {
        return '';
      }
    }, [getTextForPriority, intl, priorityFilters]);

    return (
      <Select
        text={
          isActive
            ? displayPriorityFilter
            : intl.formatMessage({ id: 'TODO_LIST.priority' })
        }
        multiSelection={true}
        onSelectionChanged={onSelectedFiltersChange}
        options={[
          {
            value: 6,
            image: ImgPriorityVeryHigh,
            text: intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.veryHighPriority',
            }),
            selected: priorityFilters.indexOf(6) !== -1,
          },
          {
            value: 5,
            image: ImgPriorityHigh,
            text: intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.highPriority',
            }),
            selected: priorityFilters.indexOf(5) !== -1,
          },
          {
            value: 4,
            image: ImgPriorityMedium,
            text: intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.mediumPriority',
            }),
            selected: priorityFilters.indexOf(4) !== -1,
          },
          {
            value: 3,
            image: ImgPriorityLow,
            text: intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.lowPriority',
            }),
            selected: priorityFilters.indexOf(3) !== -1,
          },
          {
            value: 2,
            image: ImgPriorityVeryLow,
            text: intl.formatMessage({
              id: 'ITEM_DETAILS.SprintPriority.veryLowPriority',
            }),
            selected: priorityFilters.indexOf(2) !== -1,
          },
          {
            value: 1,
            image: ImgPriorityNone,
            text: `${intl.formatMessage({
              id: 'PRIORITY.noPrioritySet',
            })}`,
            selected: priorityFilters.indexOf(1) !== -1,
          },
        ]}
      />
    );
  },
);

export default Priority;

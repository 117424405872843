import { ToDoListFilter } from '../../interfaces';
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CLEAR_CACHE } from '../../constants/general.constants';

const initialState: ToDoListFilter = {
  dueDateFilters: [],
  statusFilters: [],
  priorityFilters: [],
  projectFilters: [],
  searchFilter: '',
};

const clearCache = createAction(CLEAR_CACHE);

const slice = createSlice({
  name: 'filterOptions',
  initialState,
  reducers: {
    changeDueDateFilter: (
      state: ToDoListFilter,
      { payload },
    ): ToDoListFilter => {
      return { ...state, dueDateFilters: payload.dueDateFilters };
    },
    changeStatusFilter: (
      state: ToDoListFilter,
      { payload },
    ): ToDoListFilter => {
      return { ...state, statusFilters: payload.statusFilters };
    },
    changePriorityFilter: (
      state: ToDoListFilter,
      { payload },
    ): ToDoListFilter => {
      return { ...state, priorityFilters: payload.priorityFilters };
    },
    changeProjectFilter: (
      state: ToDoListFilter,
      { payload },
    ): ToDoListFilter => {
      return { ...state, projectFilters: payload.projectFilters };
    },
    changeSearchFilter: (
      state: ToDoListFilter,
      { payload },
    ): ToDoListFilter => {
      return { ...state, searchFilter: payload.searchFilter };
    },
  },
  extraReducers: (builder) => {
    return builder.addMatcher(isAnyOf(clearCache), () => {
      return initialState;
    });
  },
});

export const {
  changeDueDateFilter,
  changePriorityFilter,
  changeProjectFilter,
  changeSearchFilter,
  changeStatusFilter,
} = slice.actions;

export const filterOptions = slice.reducer;

import React, { FC, useCallback } from 'react';
import { Locale } from '../../../enums';
import { Dropdown, Icon } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { localeToFlag } from '../../../mappers';

interface IProps {
  locale: Locale;
  onToggleLocale(locale: Locale): void;
}

export const LanguageDropdown: FC<IProps> = ({ locale, onToggleLocale }) => {
  const intl = useIntl();

  const onLocaleItemClick = useCallback(
    (selectedLocale: Locale) => {
      onToggleLocale(selectedLocale);
    },
    [onToggleLocale],
  );

  return (
    <Dropdown
      className="profile-menu"
      trigger={
        <span>
          <Icon name="world" />
        </span>
      }
      value={locale}
    >
      <Dropdown.Menu>
        {Object.values(Locale).map((iLocale: Locale) => (
          <Dropdown.Item
            key={iLocale}
            text={intl.formatMessage({
              id: `LANGUAGE.${iLocale}`,
              defaultMessage: iLocale,
            })}
            onClick={() => onLocaleItemClick(iLocale)}
            active={iLocale === locale}
            flag={localeToFlag.get(iLocale)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;

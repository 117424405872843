import React, { FC, KeyboardEvent, memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import styles from './Search.module.scss';

interface IProps {
  onSubmit(value: string): void;
}

export const Search: FC<IProps> = memo(({ onSubmit }) => {
  const [value, setValue] = useState<string>('');

  const intl = useIntl();

  const handleKeyPress = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Enter') {
        onSubmit(value);
      }
    },
    [onSubmit, value],
  );

  return (
    <input
      id="search-field"
      className={styles.searchInput}
      placeholder={`${intl.formatMessage({
        id: 'GENERAL.search',
      })}...`}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onKeyPress={handleKeyPress}
      value={value}
    />
  );
});

export default Search;

import DDPJS from 'ddp.js';

import { store } from '../util/store';
import { userActions } from '../actions/user.actions';
import {
  CLEAR_CACHE,
  PRODUCT,
  SERVICE,
  REMOVE_FIELD,
} from '../constants/general.constants';
import {
  createRefreshToken,
  deleteRefreshToken,
} from '../tokens/refresh.token';
import { history } from '../util/history';
import {
  MessageEnum,
  MsgLevelEnum,
} from '../components/MessageDialog/MessageIndex';
import {
  setErrorMessageToStore,
  setCustomMessageToStore,
  clearErrorMessageToStore,
  isIntegrationMessage,
  isMessageSet,
} from '../components/MessageDialog/MessageUtilities';
import {
  getDisplayNameForCustomField,
  getDisplayNameForDefaultField,
} from '../util/task/fields/fields';
import { checkFlagValue } from '../util/localStorage';
import { extractFromString } from '../util/project/projectidParser';
import * as VC from '../util/versioncontrol';
import {
  addChartResultSet,
  removeChartResultSet,
} from '../store/chartResultSets/slice';
import {
  addComment,
  removeComment,
  updateComment,
} from '../store/comments/slice';
import {
  addDashboard,
  removeDashboard,
  updateDashboard,
} from '../store/dashboards/slice';
import {
  addResourceGroup,
  removeResourceGroup,
  updateResourceGroup,
} from '../store/resourceGroups/slice';
import {
  addMilestone,
  removeMileStone,
  updateMilestone,
} from '../store/milestones/slice';
import { addMultiLineField } from '../store/multiLineFields/slice';
import { addProject, updateProject } from '../store/projects/slice';
import {
  addResource,
  removeResource,
  updateResource,
} from '../store/resources/slice';
import {
  clearConnected,
  clearLoadingMode,
  setConnected,
  setDatabaseGUIDHash,
  setFeatureToggles,
  setHasDashboardsAccess,
  setLoadingMode,
  setServerVersion,
} from '../store/appState/slice';
import { addSprint, removeSprint, updateSprint } from '../store/sprints/slice';
import {
  addWorkflow,
  removeWorkflow,
  updateWorkflow,
} from '../store/workflows/slice';
import { addSingleLineField } from '../store/singleLineFields/slice';
import { addTodo, removeTodo, updateTodo } from '../store/todos/slice';
import { changeConnectionLoginID } from '../store/connection/slice';
import { clearLoginError, setLoginError } from '../store/loginError/slice';
import { ProjectAction } from '../store/sharedActions';

// Connection data. For the web client, I felt it best to control connection and reconnection myself,
// but this might change. For now, since the web service doesn't re-authenticate, reconnecting doesn't help.
const DDPOptions = {
  endpoint:
    window.location.hostname === 'localhost'
      ? 'ws://localhost:8086'
      : 'wss://' + window.location.host + '/websocket',
  // use the following string for connecting to local WebService project
  //endpoint: window.location.hostname === 'localhost' ? 'ws://localhost:8086' : 'ws://' + window.location.hostname + ':8086',
  SocketConstructor: WebSocket,
  autoConnect: false,
  autoReconnect: false,
};

// Create the DDPJS object as a singleton object
let DDPConn = new DDPJS(DDPOptions);

// Local variable for username
let gUsername;
let gLosenord;
let gSource;

// local variable to check for deliberate disconnect
let bDisconnectAction = false;

let bRefreshAction = false;

let startedSyncAt = -1;

// After the connection to the DDP service is established, this meessage is returned. I used this to set the state to Connected
DDPConn.on('connected', () => {
  console.log('Connected to ' + SERVICE);
  const datatoken = localStorage.getItem('hansoftauth');
  store.dispatch(setConnected());
  let loginid;

  if (datatoken) {
    console.log('Data Token found with authToken');
    const subdata = JSON.parse(datatoken);
    const authToken = subdata.authToken;
    gUsername = subdata.username;
    console.log('Authenticate authToken');
    loginid = DDPConn.method('authenticate', [gUsername, authToken]);
  } else {
    console.log('Authenticating with username: ' + gUsername);
    loginid = DDPConn.method('authenticate', [gUsername, gLosenord, gSource]);
    gLosenord = null;
  }
  bRefreshAction = false;
  console.log('ID for the authentication method: ' + loginid);
  store.dispatch(changeConnectionLoginID({ loginid: loginid }));
});

// This is called after a request to the DDP service is completed and returned.
DDPConn.on('result', (message) => {
  const state = store.getState();

  if (message.id !== undefined && onMethodCompleteCallbacks[message.id]) {
    onMethodCompleteCallbacks[message.id](message);
    delete onMethodCompleteCallbacks[message.id];
  }

  // everything is ok
  if ('result' in message && 'success' in message.result) {
    console.log('Result object found in message.');
    if (message.result.success) {
      console.log('Method was successful.');
      // if the return id is the same as the login request, record the username
      if (message.id === state.connection.loginID) {
        console.log(
          'ID of the returned message matches cached ID from original authentication call.',
        );
        if (gUsername) {
          store.dispatch({ type: CLEAR_CACHE });

          if (isMessageSet() && !isIntegrationMessage()) {
            clearErrorMessageToStore();
          }

          createRefreshToken();
          console.log('Starting application with username: ' + gUsername);
          if (
            message.result.userName !== undefined &&
            message.result.userName.length !== 0
          ) {
            gUsername = message.result.userName;
          }

          saveAuthToken(
            gUsername,
            message.result.authToken,
            message.result.authResourceID,
          );
          saveConfigData(message.result);
          store.dispatch(userActions.login(gUsername));
          store.dispatch(clearLoginError(message));
          console.log('Begins subscriptions.');
          subscribe();
        }
      } else if (message.id === state.connection.renewID) {
        saveAuthToken(
          gUsername,
          message.result.authToken,
          message.result.authResourceID,
        );
      }

      if (onLoginCallback) {
        onLoginCallback();
        onLoginCallback = null;
      }

      // ooops, not ok
    } else {
      if (
        message.id === state.connection.loginID ||
        message.id === state.connection.renewID
      ) {
        console.log('Login was NOT successful. ERROR');
        // login failed, record error for display
        console.log(message);
        logoffHansoft();
        if (message.id === state.connection.loginID) {
          store.dispatch(setLoginError(message));
        }
        const datatoken = localStorage.getItem('hansoftauth');
        // if there was a token and we failed to log in, it probably has expired. In any case, take user back to login and cleanup
        if (datatoken) {
          clearAuthToken();
          deleteRefreshToken();
          clearErrorMessageToStore();
          setErrorMessageToStore(
            MsgLevelEnum.WARNING,
            MessageEnum.SESSION_HAS_EXPIRED,
          );
          history.push('/login');
        }
      } else {
        console.log(message);
        let myErrorMessage = '<an unknown error occurred>';
        if ('error' in message) {
          myErrorMessage = message.error.error + ': ' + message.error.reason;
        } else if ('result' in message) {
          if ('RequiredFieldsMissing' in message.result) {
            if (Array.isArray(message.result.RequiredFieldsMissing)) {
              myErrorMessage =
                'Before setting another status you must fill in this data:';
              for (let requiredFieldMissing of message.result
                .RequiredFieldsMissing) {
                myErrorMessage += '\n';
                if (requiredFieldMissing.indexOf('CC_') === 0)
                  myErrorMessage += getDisplayNameForCustomField(
                    requiredFieldMissing,
                    message.result.ProjectID,
                  );
                else
                  myErrorMessage += getDisplayNameForDefaultField(
                    requiredFieldMissing,
                    message.result.TaskID,
                  );
              }
            }
          }
        }
        setCustomMessageToStore(MsgLevelEnum.INFORMATION, myErrorMessage);
      }
    }
    // we received something from the web service, but it's not identified as an error or not
  } else {
    console.log(
      'The message object returned does not contain a result and success value',
    );

    let mysteryMessage = '';

    if ('error' in message && 'error' in message.error) {
      mysteryMessage = message.error.error;
    } else {
      mysteryMessage = 'An unknown error has occurred in the web service';
    }

    if ('error' in message && 'reason' in message.error) {
      mysteryMessage = mysteryMessage + ': ' + message.error.reason;
    }

    setCustomMessageToStore(MsgLevelEnum.INFORMATION, mysteryMessage);
  }
});

function saveAuthToken(username, authToken, authResourceID) {
  // This is stored in the browser storage to handle refresh page
  const subdata = {
    product: 'hansoft',
    authToken: authToken,
    resourceID: authResourceID,
    username: username,
  };

  localStorage.setItem('hansoftauth', JSON.stringify(subdata));

  VC.requestAccess(authToken);
}

function saveConfigData(result) {
  // This is stored in the browser storage to handle refresh page
  const config = {
    helixCoreCommentType: result.helixCoreCommentType,
  };

  localStorage.setItem('config', JSON.stringify(config));
}

// The web service has been disconnected from
DDPConn.on('disconnected', () => {
  console.log(PRODUCT + ' has disconnected from the ' + SERVICE + '.');
  const state = store.getState();
  //If we're not already connected, it means there is a connection issue
  if (!state.appState.isConnected) {
    console.log(PRODUCT + ' was unable to connect to the ' + SERVICE + '.');
    const connectError = { error: 'Cannot connect to ' + SERVICE + '.' };
    const connectResult = { authresult: -1 };
    store.dispatch(
      setLoginError({
        result: connectResult,
        error: connectError,
      }),
    );
  } else if (bRefreshAction) {
    console.log('Disconnected from ' + PRODUCT + ' from browser refresh.');
    reconnectHansoft();
    bRefreshAction = false;
  } else if (!bDisconnectAction) {
    console.log(
      PRODUCT + ' was unexpectedly disconnected from the ' + SERVICE + '.',
    );
    setErrorMessageToStore(
      MsgLevelEnum.WARNING,
      MessageEnum.NO_LONGER_CONNECTED,
    );
    unsubscribe();
    store.dispatch({ type: CLEAR_CACHE });
    store.dispatch(userActions.logout());
    store.dispatch(clearLoginError());
    clearAuthToken();
    deleteRefreshToken();
    history.push(`/login`);
  }
  bDisconnectAction = false;
  store.dispatch(clearConnected());
});

DDPConn.on('added', (message) => {
  if (message.collection === 'ProjectTasks' || message.collection === 'Task') {
    store.dispatch(addTodo(message));
  } else if (message.collection.startsWith('ProjectMeta_')) {
    if ('id' in message) {
      if (message.id === '$Project') {
        store.dispatch(addProject(message));
      } else if ('fields' in message) {
        if ('Type' in message.fields) {
          if (message.fields.Type === 'MultiLine') {
            store.dispatch(addMultiLineField(message));
          } else {
            store.dispatch(addSingleLineField(message));
          }
        }
      }
    }
  } else if (message.collection.startsWith('ProjectResources_')) {
    store.dispatch(addResource(message));
  } else if (message.collection.startsWith('TaskComments_')) {
    const state = store.getState();
    let myTaskID = -1;
    if ('collection' in message)
      myTaskID = extractFromString('TaskComments_', message.collection);
    if (myTaskID === parseInt(state.appState.currentTaskID)) {
      store.dispatch(addComment(message));
    }
  } else if (message.collection.startsWith('ProjectSprints_')) {
    store.dispatch(addSprint(message));
  } else if (message.collection.startsWith('ProjectWorkflows_')) {
    store.dispatch(addWorkflow(message));
  } else if (message.collection.startsWith('ResourceGroups')) {
    store.dispatch(addResourceGroup(message));
  } else if (message.collection.startsWith('ProjectMilestones_')) {
    store.dispatch(addMilestone(message));
  } else if (message.collection.startsWith('Dashboards')) {
    store.dispatch(addDashboard(message));
  } else if (message.collection.startsWith('ChartResultSets')) {
    store.dispatch(addChartResultSet(message));
  } else {
    console.log('Unknown add message for ' + PRODUCT);
  }
});

DDPConn.on('changed', (message) => {
  if (message.collection === 'ServerConnectionState') {
    const state = store.getState();
    if (state.appState.serverVersion === '') {
      store.dispatch(
        setServerVersion({
          serverVersion: message.fields.serverVersion,
        }),
      );
    } else if (state.appState.serverVersion !== message.fields.serverVersion) {
      console.log('Page was refreshed for server update.');
      window.location.reload();
    }

    if (message.fields && message.fields.hasDashboardsAccess !== undefined)
      store.dispatch(
        setHasDashboardsAccess({
          hasDashboardsAccess: message.fields.hasDashboardsAccess,
        }),
      );

    if (
      message.fields &&
      (message.fields.databaseGUIDHash || message.fields.databaseServerURL)
    ) {
      store.dispatch(
        setDatabaseGUIDHash({
          databaseGUIDHash: message.fields.databaseGUIDHash || '',
          databaseServerURL: message.fields.databaseServerURL || '',
        }),
      );
    }

    if (message.fields && message.fields.featureToggles) {
      store.dispatch(
        setFeatureToggles({
          featureToggles: message.fields.featureToggles,
        }),
      );
    }

    if (message.fields && !message.fields.active) {
      logoffHansoft();
      if (localStorage.getItem('hansoftauth')) {
        clearAuthToken();
        deleteRefreshToken();
        setErrorMessageToStore(
          MsgLevelEnum.WARNING,
          MessageEnum.NO_LONGER_CONNECTED,
        );
        history.push('/login');
      }
    }

    if (message.fields && message.fields.resetConnection) {
      flagReconnect();
      reconnectHansoft();
    }
  } else if (
    message.collection === 'ProjectTasks' ||
    message.collection === 'Task'
  ) {
    store.dispatch(updateTodo(message));
  } else if (message.collection.startsWith('ProjectMeta_')) {
    if ('id' in message) {
      if (message.id === '$Project') {
        store.dispatch(updateProject(message));
      } else {
        message.type = ProjectAction.UPDATE_PROJECTMETA;
        store.dispatch(message);
      }
    }
  } else if (message.collection.startsWith('ProjectResources_')) {
    store.dispatch(updateResource(message));
  } else if (message.collection.startsWith('TaskComments_')) {
    store.dispatch(updateComment(message));
  } else if (message.collection.startsWith('ProjectSprints_')) {
    store.dispatch(updateSprint(message));
  } else if (message.collection.startsWith('ProjectWorkflows_')) {
    store.dispatch(updateWorkflow(message));
  } else if (message.collection.startsWith('ResourceGroups')) {
    store.dispatch(updateResourceGroup(message));
  } else if (message.collection.startsWith('ProjectMilestones_')) {
    store.dispatch(updateMilestone(message));
  } else if (message.collection.startsWith('Dashboards')) {
    store.dispatch(updateDashboard(message));
  } else {
    console.log('Unknown update message for ' + PRODUCT);
  }
});

DDPConn.on('removed', (message) => {
  if (message.collection === 'ProjectTasks' || message.collection === 'Task') {
    store.dispatch(removeTodo(message));
  } else if (message.collection.startsWith('ProjectMeta_')) {
    if ('id' in message && message.id !== '$ProjectID') {
      message.type = REMOVE_FIELD;
      store.dispatch(message);
    } else {
      message.type = ProjectAction.REMOVE_PROJECT;
      store.dispatch(message);
    }
  } else if (message.collection.startsWith('ProjectResources_')) {
    store.dispatch(removeResource(message));
  } else if (message.collection.startsWith('TaskComments_')) {
    store.dispatch(removeComment(message));
  } else if (message.collection.startsWith('ProjectSprints_')) {
    store.dispatch(removeSprint(message));
  } else if (message.collection.startsWith('ProjectWorkflows_')) {
    store.dispatch(removeWorkflow(message));
  } else if (message.collection.startsWith('ResourceGroups')) {
    store.dispatch(removeResourceGroup(message));
  } else if (message.collection.startsWith('ProjectMilestones_')) {
    store.dispatch(removeMileStone(message));
  } else if (message.collection.startsWith('Dashboards')) {
    store.dispatch(removeDashboard(message));
  } else if (message.collection.startsWith('ChartResultSets')) {
    store.dispatch(removeChartResultSet(message));
  } else {
    console.log('Unknown remove message for ' + PRODUCT);
  }
});

const handleInitialSub = (subs) => {
  const state = store.getState();
  if (state.appState && state.appState.loadingMode) {
    for (let subId of subs) delete initialSubscriptionsPending[subId];

    if (Object.keys(initialSubscriptionsPending).length === 0) {
      let finishedSyncAt = new Date().getTime();
      console.log(
        `Finished initial sync in ${(finishedSyncAt - startedSyncAt) / 1000}s`,
      );
      store.dispatch(clearLoadingMode());
    }
  }
};

let onReadyCallbacks = {};
let onMethodCompleteCallbacks = {};
let onNoSubCallbacks = {};

function handleCallbacks(callbacks, subscriptions) {
  for (const subId of subscriptions) {
    const callbacksforSub = callbacks[subId];
    if (callbacksforSub === undefined) continue;

    for (const callback of callbacksforSub) {
      try {
        callback();
      } catch (error) {
        // Ignore errors, but allow other callbacks to still fire.
      }
    }
  }
}

DDPConn.on('ready', (message) => {
  handleInitialSub(message.subs);
  handleCallbacks(onReadyCallbacks, message.subs);
});

DDPConn.on('nosub', (message) => {
  handleInitialSub([message.id]);
  handleCallbacks(onNoSubCallbacks, [message.id]);
});

export function hasDashboardsAccess() {
  const state = store.getState();
  return state && state.appState && state.appState.hasDashboardsAccess;
}

// Handles the login process to authenticate the user, using the DDP method
// authenticate

let onLoginCallback;

function loginHansoft(username, password, source, callback) {
  console.log('Login ' + PRODUCT);
  DDPConn.connect();
  gUsername = username;
  gLosenord = password;
  gSource = source;
  onLoginCallback = callback;
}

function flagReconnect() {
  console.log('Flag reconnect ' + PRODUCT);
  bRefreshAction = true;
  bDisconnectAction = true;
  unsubscribe();
  store.dispatch({ type: CLEAR_CACHE });
  DDPConn.disconnect();
}

function reconnectHansoft() {
  console.log('Reconnect ' + PRODUCT);
  DDPConn.connect();
}

// Handles unsubscribing and disconnecting. I'm sort of controlling this from the web client
// (when I think naturually the subscription would remain persistent) due to the need for
// authentication and current limitations in the web service
function logoffHansoft() {
  console.log('Logoff from ' + PRODUCT);
  bDisconnectAction = true;
  unsubscribe();
  store.dispatch({ type: CLEAR_CACHE });
  DDPConn.disconnect();
  store.dispatch(userActions.logout());
}

function clearCookie(cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function clearAuthToken() {
  localStorage.removeItem('hansoftauth');
  clearCookie('hsauth');
}

let initialSubscriptionsPending = {};

function registerSubscription(subName, params) {
  let subId = DDPConn.sub(subName, params);
  initialSubscriptionsPending[subId] = true;
  return subId;
}

// Handles subscriptions to the three main subs. I may change this to move ToDoList later after the other two are done for performance reasons
// (right now the list redraws as new data comes in)

let pendingSubscriptions = [];

function subscribe() {
  store.dispatch(setLoadingMode());

  startedSyncAt = new Date().getTime();

  const serverConnectionStateId = registerSubscription('ServerConnectionState');

  const myWorkID = registerSubscription('MyWork', [
    typeof checkFlagValue('showCompletedItems') === 'boolean'
      ? checkFlagValue('showCompletedItems')
      : false,
  ]);

  // This is stored in the browser storage to handle refresh page
  const subdata = {
    product: 'hansoft',
    workID: myWorkID.toString(),
    serverConnectionStateId: serverConnectionStateId.toString(),
  };

  localStorage.setItem('hansoftdata', JSON.stringify(subdata));

  for (const pending of pendingSubscriptions) pending();
}

// unsubscribes from the subs, I cached the ids for use here
function unsubscribe() {
  const datatoken = localStorage.getItem('hansoftdata');

  if (datatoken) {
    const subdata = JSON.parse(datatoken);
    DDPConn.unsub(subdata.workID);
    DDPConn.unsub(subdata.serverConnectionStateId);
  }
  localStorage.removeItem('hansoftdata');
}

function subscribeToChartResultSet(chartId) {
  return DDPConn.sub('ChartResultSet', [chartId]);
}

// Comments are done on a per item basis, otherwise same as above
function subComments(id) {
  const params = [id];
  return DDPConn.sub('TaskComments', params);
}

// Only used in tests...
window.subComments = function (taskId, resolve) {
  const subId = subComments(taskId);
  DDPConn.on('nosub', (message) => {
    if (message.id === subId) resolve(message);
  });
};

function unsubComments(taskCommentID) {
  DDPConn.unsub(taskCommentID);
}

function subscribeWithParams(subscription, params, options) {
  const subId = DDPConn.sub(subscription, params);
  if (options) {
    if (options.onReady) {
      if (onReadyCallbacks[subId] === undefined) onReadyCallbacks[subId] = [];

      onReadyCallbacks[subId].push(options.onReady);
    }

    if (options.onNoSub) {
      if (onNoSubCallbacks[subId] === undefined) onNoSubCallbacks[subId] = [];

      onNoSubCallbacks[subId].push(options.onNoSub);
    }
  }

  return subId;
}

function unsubscribeFromSubscriptionId(subscriptionId) {
  if (subscriptionId === null || subscriptionId === undefined) return;

  DDPConn.unsub(subscriptionId);
  delete onReadyCallbacks[subscriptionId];
  delete onNoSubCallbacks[subscriptionId];
}

export function fetchMoreItems(subId, numItems) {
  DDPConn.method('fetchMoreItems', [subId, numItems]);
}

// DDP method used to save field data
function setField(field, id, value) {
  if (!field || field.trim().length === 0) {
    console.log(
      'Empty field name during setField for task ID: ' +
        id +
        ' and value: ' +
        value,
    );
  }
  DDPConn.method('SetTaskField', [id, field, value]);
}

// DDP method used to save field data
function postComment(id, parent, value) {
  let myParent = parent;
  if (typeof parent === 'string') myParent = parseInt(parent, 10);
  DDPConn.method('TaskPostComment', [id, myParent, value]);
}

// DDP method used to save field data
function editComment(id, postID, value) {
  let nPostID = -1;
  if (typeof postID === 'number') {
    nPostID = postID;
  } else if (typeof postID === 'string') {
    nPostID = parseInt(postID, 10);
  }
  DDPConn.method('TaskEditComment', [id, nPostID, value]);
}

export function getLoggedInResourceID() {
  const hansoftAuth = localStorage.getItem('hansoftauth');
  if (!hansoftAuth) return -1;
  return JSON.parse(hansoftAuth).resourceID || -1;
}

export function isFeatureToggledOn(feature) {
  const state = store.getState();
  return (
    state.appState.featureToggles &&
    state.appState.featureToggles.indexOf(feature) !== -1
  );
}

export function createTask(createOptions, ddpOptions) {
  const methodId = DDPConn.method('CreateBug', [
    {
      ProjectID: createOptions.projectId,
      Fields: createOptions.fields,
    },
  ]);

  if (ddpOptions.onComplete)
    onMethodCompleteCallbacks[methodId] = ddpOptions.onComplete;
}

export {
  loginHansoft,
  flagReconnect,
  reconnectHansoft,
  logoffHansoft,
  clearAuthToken,
  subscribe,
  unsubscribe,
  setField,
  subComments,
  unsubComments,
  postComment,
  editComment,
  subscribeToChartResultSet,
  unsubscribeFromSubscriptionId,
  subscribeWithParams,
};

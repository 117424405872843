import {
  memo,
  FC,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  KeyboardEvent,
} from 'react';

interface IProps {
  value: string | number;
  onChange(value: string | number | null): void;
  selectOnFocus?: boolean;
  number?: boolean;
  decimal?: boolean;
  readOnly?: boolean;
  timeSpent?: boolean;
}

export const Input: FC<IProps> = memo(
  ({
    value,
    selectOnFocus,
    number,
    decimal,
    readOnly,
    onChange,
    timeSpent,
  }) => {
    const [currentValue, setCurrentValue] = useState(value);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (selectOnFocus) {
        inputRef.current?.select();
      }
    }, []);

    useEffect(() => {
      if (number && !isNaN(+value)) {
        setCurrentValue(value);
      }
    }, [value]);

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(event.currentTarget.value);
    };

    const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        inputRef.current?.blur();
      }
    };

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        inputRef.current?.blur();
      }
    };

    const onBlur = () => {
      const isEmpty = typeof currentValue === 'string' && !currentValue.trim();
      const parsedValue = number
        ? decimal
          ? parseFloat(currentValue.toString())
          : parseInt(currentValue.toString())
        : currentValue;

      onChange(isEmpty ? null : parsedValue);

      if (timeSpent) {
        setCurrentValue('');
      }
    };

    return (
      <input
        type="text"
        className="input js-input"
        ref={inputRef}
        value={currentValue}
        readOnly={readOnly}
        onChange={readOnly ? undefined : onInputChange}
        onBlur={readOnly ? undefined : onBlur}
        onKeyPress={readOnly ? undefined : onKeyPress}
        onKeyDown={readOnly ? undefined : onKeyDown}
      />
    );
  },
);

export default Input;

import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { history } from '../../util/history';
import { alertActions } from '../../actions/alert.constants';
import { reconnectHansoft, subscribe, unsubscribe } from '../../DDPJS/DDPJS';
import { doesRefreshTokenExist } from '../../tokens/refresh.token';
import { IntlProvider } from 'react-intl';
import enUS from '../../translations/en-US.json';
import enUK from '../../translations/en-UK.json';
import ja from '../../translations/ja.json';
import zhCN from '../../translations/zh-CN.json';
import RoutesComponent from '../Routes/RoutesComponent';
import japanese from 'date-fns/locale/ja';
import chinese from 'date-fns/locale/zh-CN';
import englishUS from 'date-fns/locale/en-US';
import englishUK from 'date-fns/locale/en-GB';

import {
  getIntegration,
  getLocale,
  getThemeName,
  checkFlagValue,
} from '../../util/localStorage';

import 'semantic-ui-css/semantic.min.css';
import '../../palette.scss';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Locale, Theme } from '../../enums';
import { Snackbar } from '../Snackbar';
import { SharedProps } from '../../interfaces';

export const App: FC = () => {
  registerLocale(Locale.JAPANESE, japanese);
  registerLocale(Locale.CHINESE, chinese);
  registerLocale(Locale.ENGLISH_UK, englishUK);
  registerLocale(Locale.ENGLISH_US, englishUS);

  if (doesRefreshTokenExist()) {
    console.log('Reconnecting to Hansoft');
    reconnectHansoft();
  }

  const dispatch = useDispatch();

  history.listen(() => {
    // clear alert on location change
    dispatch(alertActions.clear());
  });

  try {
    require('moment/locale/' + navigator.language);
    moment.locale(navigator.language);
  } catch (error) {
    try {
      if (navigator.language.indexOf('-') !== -1) {
        require('moment/locale/' + navigator.language.split('-')[0]);
        moment.locale(navigator.language.split('-')[0]);
      }
    } catch (innerError) {
      // TODO: Render error page if this error is thrown
    }
  }

  const messages = {
    'en-US': enUS,
    'en-UK': enUK,
    ja,
    'zh-CN': zhCN,
  };

  const getShowCoverImages = () => {
    const showCoverImages = localStorage.getItem('showCoverImages');
    if (showCoverImages === undefined || showCoverImages === null) return false;
    return showCoverImages === 'true';
  };

  const onToggleTheme = () => {
    localStorage.setItem(
      'theme',
      getThemeName() === Theme.DARK ? Theme.LIGHT : Theme.DARK,
    );
    setTheme(getThemeName());
  };

  const onToggleLocale = (newLocale: Locale): void => {
    localStorage.setItem('locale', newLocale);
    setDefaultLocale(newLocale);

    setLocale(newLocale);
  };

  const onToggleCoverImages = () => {
    localStorage.setItem('showCoverImages', String(!getShowCoverImages()));
    setShowCoverImages(getShowCoverImages());
  };

  const onToggleShowCompletedItems = () => {
    unsubscribe();
    localStorage.setItem(
      'showCompletedItems',
      String(!checkFlagValue('showCompletedItems')),
    );

    subscribe();
  };

  const onToggleShowOnlyFourWeeksOfTasks = () => {
    unsubscribe();

    localStorage.setItem(
      'showOnlyFourWeeksOfTasks',
      String(!checkFlagValue('showOnlyFourWeeksOfTasks')),
    );

    subscribe();
  };

  const onToggleIntegration = (integrationData: any): void => {
    let newData;
    if (integrationData !== undefined && integrationData !== null) {
      newData = {
        enabled: true,
        data: integrationData,
      };
    } else {
      newData = {
        enabled: false,
        data: {},
      };
    }
    sessionStorage.setItem('integration', JSON.stringify(newData));
    setIntegration(newData);
  };

  const handleRefresh = () => {
    console.log('Browser refresh');
    reconnectHansoft();

    return '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleRefresh);
  }, []);

  const [theme, setTheme] = useState<Theme>(getThemeName());
  const [showCoverImages, setShowCoverImages] = useState(
    checkFlagValue('showCoverImages'),
  );
  const [integration, setIntegration] = useState(getIntegration());
  const [locale, setLocale] = useState(getLocale());

  const shared: SharedProps = {
    onToggleTheme,
    theme,
    locale,
    onToggleCoverImages,
    showCoverImages,
    onToggleIntegration,
    integration,
    onToggleLocale,
    onToggleShowCompletedItems,
    onToggleShowOnlyFourWeeksOfTasks,
  };

  return (
    <div className={`app theme--${getThemeName()}`}>
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        key={locale}
        onError={() => undefined}
      >
        <RoutesComponent shared={shared} />
      </IntlProvider>
      <Snackbar />
    </div>
  );
};

export default App;

import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { setField } from '../../../DDPJS/DDPJS';
import {
  POINTS_MAX,
  POINTS_MIN,
  ESTIMATEDDAYS_MAX,
} from '../../../constants/general.constants';
import { ItemFieldWrapper } from '../../ItemDetailsPage/ItemFieldWrapper';
import { Input } from '../Input';
import { formatWork } from '../../../util/date-time/hours_field_functions';
import {
  isValidObject,
  isValidNumber,
  isValidDecimal,
} from '../../../util/validationFunctions';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import { Task } from '../../../interfaces';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../helpers';
import { getNumericSummary } from '../helpers';
import { useIntl } from 'react-intl';
import { useClickOutside } from '../../../hooks';

interface IProps {
  task: Task;
  fieldID: string;
  handler?(): void;
  unit?: string;
  isListView?: boolean;
}

export const Number: FC<IProps> = memo(
  ({ task, fieldID, handler, unit, isListView }) => {
    const intl = useIntl();

    const wrapperRef = useRef(null);

    const roundValue = (value: number) => {
      return Math.round(value * 100) / 100;
    };

    const handleClickOutside = useCallback(() => {
      setEditMode(false);
    }, []);

    useClickOutside(wrapperRef, handleClickOutside);

    const getNumberFieldValue = (input: string | number) => {
      if (input === null || input === '') return '';
      if (typeof input !== 'number') return input;
      return roundValue(input);
    };

    const [originalValue, setOriginalValue] = useState(
      getNumberFieldValue(getTaskProperty(task, fieldID)),
    );
    const [value, setValue] = useState(
      getNumberFieldValue(getTaskProperty(task, fieldID)),
    );
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
      if (
        originalValue !== getNumberFieldValue(getTaskProperty(task, fieldID)) &&
        !(
          isNaN(+originalValue) &&
          isNaN(+getNumberFieldValue(getTaskProperty(task, fieldID)))
        )
      ) {
        setValue(getNumberFieldValue(getTaskProperty(task, fieldID)));
        setOriginalValue(getNumberFieldValue(getTaskProperty(task, fieldID)));
      }
    }, [getTaskProperty(task, fieldID)]);

    const validator = (newValue: any) => {
      if (fieldID === 'ComplexityPoints') {
        if (typeof newValue !== 'number' && isNaN(parseInt(newValue, 10))) {
          newValue = value;
        } else if (parseInt(newValue, 10) > POINTS_MAX) {
          newValue = POINTS_MAX;
        } else if (parseInt(newValue, 10) < POINTS_MIN) {
          newValue = POINTS_MIN;
        }
      }

      return newValue;
    };

    const saveChanges = (newValue: any) => {
      const numberOfOriginalValue = getNumberFieldValue(originalValue);

      if (checkIfFieldIsReadOnly(fieldID, task)) {
        return numberOfOriginalValue;
      }

      setEditMode(false);
      if (typeof newValue === 'undefined') {
        return numberOfOriginalValue;
      }
      if (newValue === '') newValue = null;

      if (newValue !== null) {
        newValue = validator(newValue);

        if (!isValidDecimal(newValue)) {
          setValue(originalValue);
          return originalValue;
        }

        if (getFieldDefinition(fieldID, task).Type === 'Integer') {
          if (!isValidNumber(newValue)) {
            setValue(originalValue);
            return originalValue;
          }
        } else if (
          getFieldDefinition(fieldID, task).Type === 'Float' ||
          getFieldDefinition(fieldID, task).Type === 'Hours'
        ) {
          if (!isValidDecimal(newValue)) {
            setValue(originalValue);
            return originalValue;
          }
        }
      }

      if (newValue !== originalValue) {
        setValue(newValue);
        setOriginalValue(newValue);

        if (newValue !== null) {
          if (getFieldDefinition(fieldID, task).Type === 'Integer') {
            newValue = parseInt(newValue, 10);
          } else if (
            getFieldDefinition(fieldID, task).Type === 'Float' ||
            getFieldDefinition(fieldID, task).Type === 'Hours'
          ) {
            newValue = parseFloat(newValue);
          }
        }

        setField(getFieldDefinition(fieldID, task).id, task.$ID, newValue);
      }

      if (handler) {
        handler();
      }

      return newValue;
    };

    const getDisplayName = () =>
      `${getFieldDefinition(fieldID, task).DisplayName}${
        unit ? ` (${unit})` : ''
      }`;

    const getDisplayValue = (isDecimal: boolean, newValue: any) => {
      const summaryValue = getNumericSummary(fieldID, task, intl, newValue);
      if (summaryValue && checkIfFieldIsReadOnly(fieldID, task))
        return summaryValue;

      if (
        isDecimal &&
        isValidObject(newValue) &&
        newValue !== '' &&
        !isNaN(newValue) &&
        newValue % 1 === 0
      ) {
        const myValue = parseFloat(newValue);

        return myValue.toFixed(1);
      }
      return newValue;
    };

    const onInputChange = (newValue: any) => {
      if (newValue === null) newValue = getNumberFieldValue(newValue);
      if (
        isNaN(newValue) ||
        (newValue < 0 &&
          (fieldID === 'WorkRemaining' || fieldID === 'EstimatedIdealDays'))
      ) {
        newValue = getNumberFieldValue(value); // Reset to original
      } else if (
        newValue > ESTIMATEDDAYS_MAX &&
        fieldID === 'EstimatedIdealDays'
      ) {
        newValue = ESTIMATEDDAYS_MAX;
      }

      getDisplayValue(isFieldDecimal(), saveChanges(newValue));
    };

    const isFieldDecimal = () => {
      return (
        getFieldDefinition(fieldID, task).Type === 'Hours' ||
        getFieldDefinition(fieldID, task).Type === 'Float'
      );
    };

    if (!isValidObject(getFieldDefinition(fieldID, task))) {
      return null;
    }

    const control =
      isListView && !editMode ? (
        <div className="numberfield" onClick={() => setEditMode(true)}>
          {formatWork(value) ||
            `(${intl.formatMessage({ id: 'GENERAL.notSet' })})`}
        </div>
      ) : (
        <div ref={wrapperRef} className="numberfield-edit">
          <Input
            selectOnFocus={isListView}
            number={true}
            decimal={isFieldDecimal()}
            value={getDisplayValue(isFieldDecimal(), value)}
            readOnly={checkIfFieldIsReadOnly(fieldID, task)}
            onChange={onInputChange}
          />
        </div>
      );
    if (isListView) {
      return control;
    } else {
      return (
        <ItemFieldWrapper
          fieldName={getDisplayName()}
          isRequiredField={isWorkflowRequiredField(fieldID, task)}
        >
          {control}
        </ItemFieldWrapper>
      );
    }
  },
);

export default Number;

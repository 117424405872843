import { combineReducers } from 'redux';
import { currentUser } from './currentUser/slice';
import { chartResultSets } from './chartResultSets/slice';
import { isFilterBarCollapsed } from './isFilterBarCollapsed/slice';
import { comments } from './comments/slice';
import { dashboards } from './dashboards/slice';
import { error } from './error/slice';
import { filterOptions } from './filterOptions/slice';
import { resourceGroups } from './resourceGroups/slice';
import { milestones } from './milestones/slice';
import { multiLineFields } from './multiLineFields/slice';
import { projects } from './projects/slice';
import { resources } from './resources/slice';
import { appState } from './appState/slice';
import { sprints } from './sprints/slice';
import { workflows } from './workflows/slice';
import { singleLineFields } from './singleLineFields/slice';
import { todos } from './todos/slice';
import { connection } from './connection/slice';
import { loginError } from './loginError/slice';
import snackbar from '../reducers/snackbar.reducer';

export default combineReducers({
  todos,
  currentUser,
  filterOptions,
  connection,
  loginError,
  appState,
  multiLineFields,
  projects,
  resources,
  singleLineFields,
  isFilterBarCollapsed,
  comments,
  sprints,
  resourceGroups,
  workflows,
  error,
  milestones,
  dashboards,
  chartResultSets,
  snackbar,
});
